// Generated by Framer (7af8adc)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["A5PDOp4pg"];

const variantClassNames = {A5PDOp4pg: "framer-v-vhpdsq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "A5PDOp4pg", image: Kh1tS1QzX = {src: new URL("assets/1024/534ow7sR5pnR02mRfaot76OIUD8.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/534ow7sR5pnR02mRfaot76OIUD8.jpg", import.meta.url).href} 394w, ${new URL("assets/1024/534ow7sR5pnR02mRfaot76OIUD8.jpg", import.meta.url).href} 789w, ${new URL("assets/2048/534ow7sR5pnR02mRfaot76OIUD8.jpg", import.meta.url).href} 1579w, ${new URL("assets/4096/534ow7sR5pnR02mRfaot76OIUD8.jpg", import.meta.url).href} 3158w, ${new URL("assets/534ow7sR5pnR02mRfaot76OIUD8.jpg", import.meta.url).href} 4204w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "A5PDOp4pg", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FLM60", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 5452, intrinsicWidth: 4204, pixelHeight: 5452, pixelWidth: 4204, ...toResponsiveImage(Kh1tS1QzX)}} className={cx("framer-vhpdsq", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"A5PDOp4pg"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FLM60 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FLM60 * { box-sizing: border-box; }", ".framer-FLM60 .framer-miooa7 { display: block; }", ".framer-FLM60 .framer-vhpdsq { height: 682px; overflow: hidden; position: relative; width: 880px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 682
 * @framerIntrinsicWidth 880
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Kh1tS1QzX":"image"}
 */
const FramerEwr_Yj6Hp: React.ComponentType<Props> = withCSS(Component, css, "framer-FLM60") as typeof Component;
export default FramerEwr_Yj6Hp;

FramerEwr_Yj6Hp.displayName = "Tile";

FramerEwr_Yj6Hp.defaultProps = {height: 682, width: 880};

addPropertyControls(FramerEwr_Yj6Hp, {Kh1tS1QzX: {__defaultAssetReference: "data:framer/asset-reference,534ow7sR5pnR02mRfaot76OIUD8.jpg?originalFilename=black+textile+on+white+textile.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerEwr_Yj6Hp, [])